<template>
  <div class="container">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card
        elevation="2"
        outlined
        shaped
        tile
        style="max-width: 100%;max-width:500px;"
      >
        <v-card-text>
          <div>
            <div class="text-center">
              <img
                class="mb-4"
                src="@/assets/logo.png"
                alt=""
                style="width: 300px"
              />
            </div>

            <label for="inputUser" class="sr-only">Email</label>

            <v-text-field
              id="user-email"
              v-model="email"
              v-bind:placeholder="`Nombre de usuario`"
              required
              :rules="emailRules"
            ></v-text-field>

            <div class="checkbox mb-3"></div>

            <label for="inputPassword" class="sr-only">Contraseña</label>

            <v-text-field
              v-model="password"
              :type="'password'"
              v-bind:placeholder="`Contraseña`"
              required
              :rules="passwordRequiredRules"
            ></v-text-field>

            <router-link to="/auth/forgotpassword"
              >Me he Olvidado del password</router-link
            >

            <div class="checkbox mb-3"></div>

            <v-btn
              color="blue darken-1"
              block
              @click="onSubmit(email, password)"
            >
              {{ $t("login.login") }}
            </v-btn>

            <b-alert v-if="isUnderMaintenance" class="my-2" variant="warning" show>
              <h5 class="justify-center"><b-icon icon="exclamation-circle"></b-icon> En mantenimiento</h5>
              <p>Estamos temporalmente fuera de servicio.<br>
                Disculpa las molestias</p>
            </b-alert>

            <p class="mt-1 text-sm-center small">
              &copy; Trencadis Business Solutions, SL
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",
  computed: {
    ...mapGetters('auth',['isUnderMaintenance']),
  },
  data() {
    return {
      valid: true,
      email: null,
      password: null,
      passwordRequiredRules: [
        v => !!v || "El campo de contraseña es obligatorio"
      ],
      emailRules: [
        v => !!v || "El campo de email es obligatorio",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "El email proporcionado debe ser una dirección de email válida"
      ]
    };
  },

  methods: {
    onSubmit(email, password) {
      let self = this;
      if (self.$refs.form.validate()) {
        self.$store.dispatch("auth/login", { email, password });
      }
    }
  },

  mounted() {
    // let self = this;
    // window.addEventListener("keyup", function(event) {
    //   if (event.keyCode === 13) {
    //     self.onSubmit(self.email, self.password);
    //   }
    // });
  }
};
</script>

<style scoped>
.container {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
